
import {defineComponent, onMounted} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import Overview from "@/view/pages/resources/documentation/general/font-awesome/Overview.vue";
import Usage from "@/view/pages/resources/documentation/general/font-awesome/Usage.vue";
import IconColors from "@/view/pages/resources/documentation/general/font-awesome/IconColors.vue";
import IconSizes from "@/view/pages/resources/documentation/general/font-awesome/IconSizes.vue";

export default defineComponent({
  name: "font-awesome",
  components: {
    Overview,
    Usage,
    IconColors,
    IconSizes,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Font Awesome");
    });
  },
});
